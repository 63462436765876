import React from 'react'

function Testimonial2() {
  return (
    <div className="testimonial2-section">
        <div className="testimonial2_bg">
            <div className="container-fluid testimonial2_overlay">
                <div className="row d-flex justify-content-center">
                    <div className="col-12  fitness2_main">
                        <h1 className='testimonial_h1 pb-2 text-center'>Some Awesomeness that should share</h1>
                        <p className='testimonial_p text-center'>We are in extremely love with eco friendly system.</p>
                    </div>
                </div>
                <div className="row testimonial2_sec d-flex justify-content-evenly">
                    <div className="col-lg-4 col-md-6 testimonial_box my-3">
                        <h4 className="testimonial2_h4"><i className="fa-regular fa-user mx-3"></i>Expert Technicians</h4>
                        <p className='testimonial2_p'>At Auroxa Communication, our expert technicians are dedicated to delivering top-notch solutions, ensuring your needs are met with precision and care.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 testimonial_box my-3">
                        <h4 className="testimonial2_h4"><i className="fa-regular fa-user mx-3"></i>Professional Service</h4>
                        <p className='testimonial2_p'>At Auroxa Communication, we pride ourselves on delivering exceptional service with a focus on quality and client satisfaction, ensuring a seamless experience every time.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 testimonial_box my-3">
                        <h4 className="testimonial2_h4"><i class="fa-solid fa-tty mx-3"></i>Great Support</h4>
                        <p className='testimonial2_p'>At Auroxa Communication, we provide great support that goes above and beyond, ensuring you receive the assistance you need, whenever you need it.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 testimonial_box my-3">
                        <h4 className="testimonial2_h4"><i class="fa-solid fa-rocket mx-3"></i>Technical Skills</h4>
                        <p className='testimonial2_p'>At Auroxa Communication, our team possesses cutting-edge technical skills that drive innovative solutions, empowering your business to thrive in a digital landscape.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 testimonial_box my-3">
                        <h4 className="testimonial2_h4"><i class="fa-regular fa-gem mx-3"></i>Highly Recomended</h4>
                        <p className='testimonial2_p'>At Auroxa Communication, our commitment to excellence and client satisfaction has earned us strong recommendations, making us the trusted choice for communication solutions.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 testimonial_box my-3">
                        <h4 className="testimonial2_h4"><i class="fa-regular fa-comment mx-3"></i>Positive Reviews</h4>
                        <p className='testimonial2_p'>At Auroxa Communication, we take pride in the positive reviews we receive, reflecting our dedication to exceptional service and client satisfaction.</p>
                    </div>
                </div>
            </div>    
        </div>
    </div>
  )
}

export default Testimonial2
