import React from 'react'

function FormContact() {
  return (
    <div className="container " id="forms">
        <div className="row d-flex justify-content-center contact_row">
            <div className="col-12">
                <h1 className='fs_h1 pb-2 text-center'>If you need, Just mail us</h1>
                <div className='text-center'><p className='fs_p text-center'><span className='mx-2'><i class="fa-solid fa-envelope"></i></span>auroxacommunication@gmail.com</p></div>
            </div>
        </div>
    </div>
  )
}

export default FormContact
