import React from 'react'
import s1 from "../pics/s1.png.webp"
import s2 from "../pics/s2.png.webp"
import s3 from "../pics/s3.png.webp"

function Services() {
  return (
    <>
        <div className="container d-flex services_section" id="services">
            <div className="col-md-4 services_1">
                <img src={s1} alt="pic" className='pb-4' />
                <h4 className='services_h pb-3'>Online Support</h4>
                <p className='services_p'>At Auroxa Communication, we prioritize innovation and excellence. Our team is dedicated to creating tailored solutions that drive success and foster growth for businesses of all sizes.</p>
            </div>
            <div className="col-md-4 services_2">
            <img src={s2} alt="pic" className='pb-4' />
            <h4 className='services_h pb-3'>24/7 Cab Booking Service</h4>
            <p className='services_p'>At Auroxa Communication, we're here for you around the clock. No matter the time, our expert team is ready to assist with any inquiries or concerns.</p>
            </div>
            <div className="col-md-4 services_3">
            <img src={s3} alt="pic" className='pb-4' />
            <h4  className='services_h pb-3'>Handling All Issues</h4>
            <p className='services_p'>At Auroxa Communication, we take pride in handling all issues with efficiency and care. Whether it’s a minor inquiry or a complex problem, our dedicated team is always ready to provide swift, effective solutions, ensuring uninterrupted success for your business.</p>
            </div>
        </div>
    </>
  )
}

export default Services
