import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import Services from "./components/Services";
import Testimonial from "./components/Testimonial";
import ContentSynchronization from "./components/ContentSynchronization"
import ProjectCompleted from "./components/ProjectCompleted";
import Testimonial2 from "./components/Testimonial2";
import CustomerSupport from "./components/CustomerSupport";
import ArchitectureSection from "./components/ArchitectureSection";
import PremiumSection from "./components/PremiumSection";
// import FormSection from "./components/FormSection";
import FooterSection from "./components/FooterSection";
import FormContact from "./components/FormContact";

function App() {
  return (
    <>
      
        <Navbar />
        <HeroSection />
        <Services />
        <Testimonial />
        <ContentSynchronization />
        <ProjectCompleted />
        <Testimonial2 />
        <CustomerSupport />
        <ArchitectureSection />
        <PremiumSection />
        <FormContact/>
        {/* <FormSection /> */}
        <FooterSection />
    </>
  );
}

export default App;
