import React from 'react'
import user1 from "../pics/user1.png.webp"
import user2 from "../pics/user2.png.webp"
// import Slider from './Slider'

function Testimonial() {
  return (
        <div className="testimonial_bg">
            <div className="container-fluid testimonial_overlay">
                <div className="row d-flex justify-content-center">
                    <div className="col-12  fitness_main">
                        <h1 className='testimonial_h1 pb-2 text-center'>Why Choose Us Your Fitness Builder</h1>
                        <p className='testimonial_p text-center'>We are in extremely love with eco friendly system.</p>
                    </div>
                </div>
                <div className="container">
                <div className="row testimonial_row2">
                   <div className='my_card'><div className="col-md-5 card testimonial_card1">
                        <div className='d-flex pt-4 pb-3 card1_div'>
                            <div className='ms-4'><img src={user1} alt="pic" width="50px" /></div>
                            <div className='mx-4'>
                            <p className='testimonial_card_p'>Auroxa Communication has transformed our business with their exceptional support and expertise. Their team swiftly resolves any issues, ensuring seamless operations. We highly recommend their services to anyone seeking reliability and professionalism!</p>
                            <h4 className='testimonial_h4'>John Anderson</h4>
                            <p className='testimonial_card_p'>Anderson Tech Solutions</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 card testimonial_card2">
                        <div className='d-flex pt-4 pb-3 card2_div'>
                            <div className='ms-4'><img src={user2} alt="pic" width="50px" /></div>
                            <div className='mx-4'>
                            <p className='testimonial_card_p'>Auroxa Communication has transformed our business with their exceptional support and expertise. Their team swiftly resolves any issues, ensuring seamless operations. We highly recommend their services to anyone seeking reliability and professionalism!</p>
                            <h4 className='testimonial_h4'>Emily Rodriguez</h4>
                            <p className='testimonial_card_p'>Creative Minds Studio</p>
                            </div>
                        </div>
                    </div>  </div> 
                </div>
                </div>
            </div>    
        </div>
  )
}

export default Testimonial
