import React from 'react'
import play_img from "../pics/play-btn.png.webp"

function ArchitectureSection() {
  return (
   <div className="architecture-section">
     <div className="container-fluid">
        <div className="row">
        <div className="col-lg-6 col-md-12 as_left">
            <div className="col-md-7 offset-lg-5">
            <h1 className='as_h1'>Transforming Spaces with Auroxa Communication's Architectural Solutions</h1>
            <p className='as_p'>At Auroxa Communication, we believe that exceptional architecture goes beyond structure—it’s about creating environments that connect people and inspire creativity. Our architectural solutions focus on blending form with function, crafting spaces that not only look stunning but also enhance the way you live, work, and interact. With a keen eye for detail and a passion for design, we transform ordinary spaces into extraordinary experiences. Let Auroxa Communication bring your vision to life, shaping environments that reflect your style and elevate your everyday life.</p>
            <div className="btn cs_btn">SEE DETAILS<span className='cs_icon'><i className="fa-solid fa-arrow-right-long"></i></span></div>
            </div>
        </div>
        <div className="col-lg-6 col-md-12 as_right">
            <div className="as_over_lay">
            <a href="https://youtu.be/hpZFJctBUHQ?si=1gjnnr8A9gX-Ts4A" target="_blank" rel="noreferrer" className='play-btn'><img src={play_img} alt="pic" className='play_img'/></a>
            </div>  
        </div>
        </div>
    </div>
   </div>
  )
}

export default ArchitectureSection
