import React, { useState } from 'react';
import { Link } from 'react-scroll';
import logo from "../pics/logo.png";

function Navbar() {
    const [activeLink, setActiveLink] = useState('HOME'); // State to track the active link
    const [hoveredLink, setHoveredLink] = useState(null); // State to track the hovered link

    // Handle hover to change active link
    const handleMouseEnter = (linkName) => {
        setHoveredLink(linkName); // Set the hovered link as active temporarily
    };

    // Handle mouse leave to restore original active link
    const handleMouseLeave = () => {
        setHoveredLink(null); // Reset hovered link
    };

    const handleClick = (linkName)=>{
        setActiveLink(linkName);
    }

    return (
        <div className='navbar-section sticky-top'>
        <nav className="navbar navbar-expand-lg my_navbar">
            <div className="container-fluid pt-3 check2">
                <div className='logo-container'><img src={logo} alt="pic" height="60" className="logo" /></div>
                <button className="nav_btn navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse content" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 lg-0 ms-4">
                        <li className="nav-item mx-1">
                            <Link 
                                className={`nav-link ${hoveredLink === 'HOME' || (!hoveredLink && activeLink === 'HOME') ? 'active' : ''}`} 
                                to="/" 
                                spy={true} 
                                smooth={true} 
                                offset={-100} 
                                duration={500} 
                                onMouseEnter={() => handleMouseEnter('HOME')} 
                                onMouseLeave={handleMouseLeave}
                                onClick={() => handleClick('HOME')}
                            >
                                HOME
                            </Link>
                        </li>
                        <li className="nav-item mx-1 nav_content">
                            <Link 
                                className={`nav-link ${hoveredLink === 'FEATURE' || (!hoveredLink && activeLink === 'FEATURE') ? 'active' : ''}`} 
                                to="services" 
                                spy={true} 
                                smooth={true} 
                                offset={-100} 
                                duration={500} 
                                onMouseEnter={() => handleMouseEnter('FEATURE')} 
                                onMouseLeave={handleMouseLeave}
                                onClick={() => handleClick('FEATURE')}
                            >
                                FEATURE
                            </Link>
                        </li>
                        <li className="nav-item mx-1 nav_content">
                            <Link 
                                className={`nav-link ${hoveredLink === 'WE OFFER' || (!hoveredLink && activeLink === 'WE OFFER') ? 'active' : ''}`} 
                                to="content" 
                                spy={true} 
                                smooth={true} 
                                offset={-100} 
                                duration={500} 
                                onMouseEnter={() => handleMouseEnter('WE OFFER')} 
                                onMouseLeave={handleMouseLeave}
                                onClick={() => handleClick('WE OFFER')}
                            >
                                WE OFFER
                            </Link>
                        </li>
                        <li className="nav-item mx-1 nav_content">
                            <Link 
                                className={`nav-link ${hoveredLink === 'ABOUT' || (!hoveredLink && activeLink === 'ABOUT') ? 'active' : ''}`} 
                                to="customer" 
                                spy={true} 
                                smooth={true} 
                                offset={-100} 
                                duration={500} 
                                onMouseEnter={() => handleMouseEnter('ABOUT')} 
                                onMouseLeave={handleMouseLeave}
                                onClick={() => handleClick('ABOUT')}
                            >
                                ABOUT
                            </Link>
                        </li>
                        <li className="nav-item mx-1 nav_content">
                            <Link 
                                className={`nav-link ${hoveredLink === 'CONTACT' || (!hoveredLink && activeLink === 'CONTACT') ? 'active' : ''}`} 
                                to="forms" 
                                spy={true} 
                                smooth={true} 
                                offset={-100} 
                                duration={500} 
                                onMouseEnter={() => handleMouseEnter('CONTACT')} 
                                onMouseLeave={handleMouseLeave}
                                onClick={() => handleClick
                                    ('CONTACT')}
                            >
                                CONTACT  
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        </div>
    );
}

export default Navbar;
