import React from 'react'

function ProjectCompleted() {
    return (
        <div className="container pro_body">
            <div className="row pro_row d-flex justify-content-evenly">
                <div className="pro_col col-md-2">
                    <h1 className="pro_h1">12</h1>
                    <p className="pro_p">Projects Completed</p>
                </div>
                <div className="pro_col col-md-2">
                    <h1 className="pro_h1">24</h1>
                    <p className="pro_p">Really Happy Clients</p>
                </div>
                <div className="pro_col col-md-2">
                    <h1 className="pro_h1">16</h1>
                    <p className="pro_p">Total Tasks Completed</p>
                </div>
                <div className="pro_col col-md-2">
                    <h1 className="pro_h1">58</h1>
                    <p className="pro_p">Cups of Coffee Taken</p>
                </div>
                <div className="pro_col col-md-2">
                    <h1 className="pro_h1">23</h1>
                    <p className="pro_p">In House Professionals</p>
                </div>
            </div>
        </div>
    )
}

export default ProjectCompleted
