import React from 'react'
import i1 from "../pics/l1.png.webp"
import i2 from "../pics/l2.png.webp"
import i3 from "../pics/l3.png.webp"
import i4 from "../pics/l4.png.webp"
import i5 from "../pics/l5.png.webp"

function PremiumSection() {
  return (
    <div className="container">
        <div className="row ps_row d-flex justify-content-evenly">
            <div className="col-md-2 col-9 offset-3 offset-md-0  ps_col">
                <img src={i1} alt="pic" />
            </div>
            <div className="col-md-2 col-9 offset-3 offset-md-0 ps_col">
                <img src={i2} alt="pic" />
            </div>
            <div className="col-md-2 col-9 offset-3 offset-md-0 ps_col">
                <img src={i3} alt="pic" />
            </div>
            <div className="col-md-2 col-9 offset-3 offset-md-0 ps_col">
                <img src={i4} alt="pic" />
            </div>
            <div className="col-md-2 col-9 offset-3 offset-md-0 ps_col">
                <img src={i5} alt="pic" />
            </div>
        </div>
    </div>
  )
}

export default PremiumSection
