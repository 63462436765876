import React from 'react'

function Hero_section() {
  return (
    <div className="container hero_body">
        <div className="row">
        <div className="col-md-12 hero_img ">
          <div className="col-md-7 offset-md-5 hero_content">
            <p className='hero_p1'>DISCOVER THE COLORFUL WORLD</p>
            <h1 className="hero_h1">Leads Generation</h1>
            <p className='hero_p2'>At Auroxa Communication, we believe in building meaningful connections. Our mission is to empower businesses with cutting-edge communication solutions that drive success and foster growth. Whether you're looking to enhance your brand's visibility or streamline internal communications</p>
            <div className="btn hero_btn">DISCOVER NOW</div>
          </div>
        </div>
        </div>
    </div>
  )
}

export default Hero_section
