import React from 'react'
import o1 from "../pics/o1.png.webp"
import o2 from "../pics/o2.png.webp"
import o3 from "../pics/o3.png.webp"
import o4 from "../pics/o4.png.webp"
import o5 from "../pics/o5.png.webp"
import o6 from "../pics/o6.png.webp"

function ABC() {
  return (
    <>
        <div className="container abc_body" id="content">
            <div className="row">
            <div className="col-md-6 abc_left">
                <div className="row abc_r d-flex justify-content-between mt-4">
                    <div className="col-3 abc_card card d-flex justify-content-center align-items-center">
                        <img src={o1} alt="pic" />
                    </div>
                    <div className="col-3 abc_card card d-flex justify-content-center align-items-center">
                        <img src={o2} alt="pic" />
                    </div>
                    <div className="col-3 abc_card card d-flex justify-content-center align-items-center">
                        <img src={o3} alt="pic" />
                    </div>
                </div>
                <div className="row abc_r d-flex justify-content-between mt-4 mb-4">
                    <div className="col-3 abc_card card d-flex justify-content-center align-items-center">
                        <img src={o4} alt="pic" />
                    </div>
                    <div className="col-3 abc_card card d-flex justify-content-center align-items-center">
                        <img src={o5} alt="pic" />
                    </div>
                    <div className="col-3 abc_card card d-flex justify-content-center align-items-center">
                        <img src={o6} alt="pic" />
                    </div>
                </div>
            </div>
            <div className="col-md-4 abc_right">
                <h4 className='abc_h4'>Content Synchronization</h4>
                <p className='abc_p pt-3'>At Auroxa Communication, we ensure that all communication channels are aligned for a seamless customer experience. Our call center solutions provide consistent information, fostering trust and loyalty. Let us help you elevate your customer interactions through effective content synchronization.</p>
                <div className="btn hero_btn">VIEW DETAILS</div>
            </div>
            </div>
        </div>
    </>
  )
}

export default ABC
